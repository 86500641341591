import React, { useState } from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

import Loader from 'src/components/atoms/Loader'
import Heading from 'src/components/molecules/Heading'
import Card from 'src/components/molecules/Card'

const Container = styled.div`
`;

const Archive = ({ title, titleEn, type, posts }) => {
  const perPage = 10;
  const [showPostIndex, setShowPostIndex] = useState( perPage )
  const [showPosts, setShowPosts] = useState( posts.node ? posts.node: posts )

  return (
    <Container>
      { title && titleEn &&
        <Heading type={ type } title={ title } titleEn={ titleEn } />
      }

      { posts &&
        ( type === 'main' ?
          (
            <InfiniteScroll
              dataLength={showPostIndex}
              next={() => setTimeout(() => { setShowPostIndex(showPostIndex + perPage)}, 1000)}
              hasMore={showPosts.length > showPostIndex}
              useWindow={true}
              loader={
                <Loader />
              }>
                {showPosts.slice(0, showPostIndex).map(( post, key ) => (
                  <Card key={ key } post={ post.node ? post.node: post } type={ type } />
                ))}
            </InfiniteScroll>
          ):
          (
            posts.node ?
              ( posts.map(({ node: post }) => ( <Card key={ post.id } post={ post } type={ type } /> ))):
              ( posts.map(( post ) => ( <Card key={ post.id } post={ post } type={ type } /> )))
          )
        )
      }
    </Container>
  )
}

Archive.propTypes = {
  title: PropTypes.string,
  titleEn: PropTypes.string,
  type: PropTypes.string,
  posts: PropTypes.array,
}

export default Archive
