import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Heading from 'src/components/molecules/Heading'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.nav`

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin: 0 2rem 2rem;
  `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  color: ${Settings.colors.textColor};
  text-decoration: none;
`;

const ItemLink = styled(Link)`
  color: ${Settings.colors.textColor};
  text-decoration: none;
`;

const Sitemap = ({ settings }) => (
  <Container>
    <Heading type="side" title="サイトコンテンツ" titleEn="Site Contents" />
    <List>
      <Item><ItemLink to={ `/about` }>ペトラとは？</ItemLink></Item>
      <Item><ItemLink to={ `/privacy` }>プライバシーポリシー</ItemLink></Item>
    </List>
  </Container>
)

Sitemap.propTypes = {
  settings: PropTypes.object,
}

export default Sitemap
