import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

import About from 'src/components/molecules/About'
import Ads from 'src/components/atoms/Ads'
import Populary from 'src/components/molecules/Populary'
import Social from 'src/components/molecules/Social'
import Sitemap from 'src/components/molecules/Sitemap'

const Container = styled.aside`

  ${Media.desktop`
    width: 300px;
  `}
`;

const Sticky = styled.div`

  ${Media.desktop`
    position: sticky;
    top: 2rem;
  `}
`;

const Copyright = styled.div`

  ${Media.desktop`
    color: ${Settings.colors.textLightColor};
  `}

  ${Media.mobile`
    display: none;
  `}
`;


const Side = ({ settings }) => (
  <Container role="banner">
    <About settings={ settings } />
    <Ads />
    <Populary />
    <Sticky>
      <Social settings={ settings } />
      <Ads rectangle />
      <Sitemap />
      <Copyright>© Petra</Copyright>
    </Sticky>
  </Container>
)

Side.propTypes = {
  settings: PropTypes.object,
}

export default Side
