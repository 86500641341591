import React from 'react'
import styled, { css, keyframes  } from 'styled-components'
import Settings from 'src/styles/settings'

const BallScaleRipple = keyframes`
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0.0;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const Item = styled.div`
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 2px solid ${Settings.colors.keyColor};
  animation: ${BallScaleRipple} 1s 0s infinite cubic-bezier(.21,.53,.56,.8);
`;

const Loader = () => (
  <Container>
    <Item />
  </Container>
)

export default Loader
