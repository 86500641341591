import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from "gatsby-image"

import Settings from 'src/styles/settings'

import { Media, Neumorphism, DropShadow } from 'src/styles/mixins'

const Container = styled.article`
  margin-bottom: 2rem;
`;

const CardLink = styled(Link)`
  display: block;
  color: ${Settings.colors.textColor};
  text-decoration: none;

  ${props => props.type === "main" && css`

    ${Media.desktop`
      position: relative;
      padding-left: 150px;

      &:hover ${Content} {
        ${Neumorphism};
      }
    `}
  `}
`;

const Thumbnail = styled.div`

  ${props => props.type === "side" && css`
    margin-bottom: .5rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}

  ${Media.desktop`
    ${props => props.type === "main" && css`
      position: absolute;
      top: 2rem;
      left: 0;
      width: 300px;
    `}
  `}
`;

const Image = styled(Img)`
  max-width: 100%;

  ${props => props.type === "main" && css`
    max-width: 100%;
  `}

  ${Media.desktop`
    border-radius: .5rem;
    transition: box-shadow ${Settings.sizes.sec} ease;

    ${CardLink}:hover & {
      ${DropShadow};
    }
  `}
`;

const Content = styled.div`

  ${Media.mobile`
    padding: 0 1rem;
  `}

  ${Media.desktop`
    ${props => props.type === "main" && css`
      border-radius: .5rem;
      padding: 1.5rem 2rem 1.5rem 176px;
      transition: box-shadow ${Settings.sizes.sec} ease;
    `}
  `}
`;

const Label = styled.div`
  position: relative;
  margin-bottom: .2rem;
  padding-left: 2.5rem;
  color: ${Settings.colors.keyColor};
  font-size: .875rem;

  ${Media.mobile`
    display: none;
  `}

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    border-top: 1px solid ${Settings.colors.keyColor};
    content: "";
  }
`;

const Title = styled.h1`
  margin: 0 0 .5rem;

  ${props => props.type === "main" && css`

    ${Media.mobile`
      font-size: 1.14rem;
    `}

    ${Media.desktop`
      font-size: 1.5rem;
    `}
  `}

  ${props => props.type === "side" && css`

    ${Media.mobile`
      font-size: 1.14rem;
    `}

    ${Media.desktop`
      font-size: 1rem;
    `}
  `}
`;

const Description = styled.div`
  margin-bottom: .5rem;

  ${Media.desktop`
    ${props => props.type === "side" && css`
      display: none;
    `}
  `}
`;

const Date = styled.div`
  font-size: .875rem;
  text-align: right;
`;

const Card = ({ post, type }) => (
  <Container>
    <CardLink type={ type } to={`/${post.slug}`} title={ post.title }>
      <Thumbnail type={ type }>
        <Image type={ type } fluid={ post.image.fluid } alt={ post.title } />
      </Thumbnail>
      <Content type={ type }>
        { post.tags && type === 'main' &&
          post.tags.map(( tag ) => {
            if (tag.group === 1) {
              return <Label dangerouslySetInnerHTML={{ __html: tag.title }} />
            }
          })
        }
        <Title type={ type } dangerouslySetInnerHTML={{ __html: post.title }} />
        <Description type={ type } dangerouslySetInnerHTML={{ __html: post.excerpt.childMarkdownRemark.excerpt }} />
        <Date dangerouslySetInnerHTML={{ __html: post.date }} />
      </Content>
    </CardLink>
  </Container>
)

Card.propTypes = {
  post: PropTypes.object,
  type: PropTypes.string,
}

export default Card
