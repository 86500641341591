import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Archive from 'src/components/molecules/Archive'
import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.nav`
`;

const Icon = styled.div`
  position: relative;
  z-index: 1;
  float: left;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin-right: 1rem;
  border-radius: 60px;

  ${Media.mobile`
    margin: 0 auto .6rem;
    float: none;
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform ${Settings.sizes.sec} ease;
`;

export default function Populary() {

  const data = useStaticQuery(graphql`
    query {
      allPageViews(limit: 5) {
        edges {
          node {
            totalCount
            id
          }
        }
      }

      allContentfulPosts(limit: 1000, filter: {node_locale: {eq: "ja-JP"}}) {
        edges {
          node {
            id
            title
            image {
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid
              }
            }
            excerpt {
              childMarkdownRemark {
                excerpt
              }
            }
            slug
            date(formatString: "YYYY.MM.DD")
            createdAt(formatString: "YYYY.MM.DD")
            updatedAt(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  `);

  const posts = data.allContentfulPosts.edges;
  const allPageViews = data.allPageViews.edges;
  const popularies = [];

  const modifiedPageViews = allPageViews
  .sort(({ node: a }, { node: b }) => b.totalCount - a.totalCount) // countの大きい順にソートしている。
  .filter(({ node }) => node.id !== '/') // TOPページは除外する
  .slice(0, 10) // top 10の人気記事のみ抽出
  .map(({ node }) => {
    posts.map(({ node: post }) => {
      if ('/' + post.slug === node.id) {
        popularies.push({
          count: node.totalCount,
          ...post,
        })
      }
    })
  })

  return (
    <Archive title="人気の記事" titleEn="Popular Articles" type="side" posts={ popularies } />
  )
}