import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Media } from 'src/styles/mixins'
import Settings from 'src/styles/settings'

const Container = styled.h1`
  margin: 0;

  ${props => props.type === "main" && css`
    font-size: 2rem;

    ${Media.desktop`
      margin-bottom: 1rem;
    `}

    ${Media.mobile`
      margin-bottom: 2rem;
      padding: 0 1rem;
      font-size: 1.5rem;
    `}
  `}

  ${props => props.type === "side" && css`
    font-size: 1.14rem;

    ${Media.desktop`
      margin-bottom: 1rem;
    `}

    ${Media.mobile`
      margin-bottom: 2rem;
      padding: 0 1rem;
      font-size: 1.5rem;
    `}
  `}
`;

const Label = styled.span`
  display: block;
  color: ${Settings.colors.keyColor};

  ${props => props.type === "main" && css`
    font-size: 1rem;

    ${Media.desktop`
    `}

    ${Media.mobile`
    `}
  `}

  ${props => props.type === "side" && css`
    font-size: .875rem;

    ${Media.desktop`
    `}

    ${Media.mobile`
    `}
  `}
`;

const Heading = ({ type, title, titleEn }) => (
  <Container type={ type }>
    <Label type={ type } dangerouslySetInnerHTML={{ __html: titleEn }} />
    { title }
  </Container>
)

Heading.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  titleEn: PropTypes.string,
}

export default Heading
