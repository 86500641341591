import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import IcoTwitter from "src/images/ico_twitter.svg"
import IcoFacebook from "src/images/ico_facebook.svg"
import IcoInstagram from "src/images/ico_instagram.svg"
import IcoLine from "src/images/ico_line.svg"

import Settings from 'src/styles/settings'

import { Media, Neumorphism } from 'src/styles/mixins'

const Container = styled.aside`
  position: relative;
  padding: 2rem 2rem 160px;
  border-radius: .5rem;
  ${Neumorphism};

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 160px;
    background: url('/social.svg') no-repeat center bottom / contain;
  }

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin: 0 2rem 2rem;
  `}
`;

const Title = styled.h1`
  margin: 0;
  color: ${Settings.colors.keyColor};
  text-align: center;
  margin-bottom: .5rem;
  font-size: .875rem;
`;

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  display: inline-block;
  margin: 0 .2rem;
  letter-spacing: normal;
`;

const ItemLink = styled.a`
  display: block;
  padding: .5rem;
`;

const TwitterImage = styled(IcoTwitter)`
  width: 100%;
  height: auto;
`;

const FacebookImage = styled(IcoFacebook)`
  width: 100%;
  height: auto;
`;

const InstagramImage = styled(IcoInstagram)`
  width: 100%;
  height: auto;
`;

const LineImage = styled(IcoLine)`
  width: 100%;
  height: auto;
`;

const Social = ({ settings }) => (
  <Container>
    <Title>Follow me!!</Title>
    <List>
      <Item>
        <ItemLink twitter href={`https://twitter.com/${settings.twitter}`} title="Twitter" target="_blank" rel="noreferrer noopener">
          <TwitterImage />
        </ItemLink>
      </Item>
      <Item>
        <ItemLink facebook href={`https://www.facebook.com/${settings.facebook}`} title="Facebook" target="_blank" rel="noreferrer noopener">
          <FacebookImage />
        </ItemLink>
      </Item>
      <Item>
        <ItemLink instagram href={`https://www.instagram.com/${settings.instagram}`} title="Instagram" target="_blank" rel="noreferrer noopener">
          <InstagramImage />
        </ItemLink>
      </Item>
      <Item>
        <ItemLink line href={`https://line.me/R/ti/p/${settings.line}`} title="LINE" target="_blank" rel="noreferrer noopener">
          <LineImage />
        </ItemLink>
      </Item>
    </List>
  </Container>
)

Social.propTypes = {
  settings: PropTypes.object,
}

export default Social
