import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import LogoImage from "src/images/logo.svg"

import Settings from 'src/styles/settings'

import { Media, Neumorphism } from 'src/styles/mixins'

const Container = styled.div`
  position: relative;
  padding: 2rem 2rem 1rem 160px;
  border-radius: .5rem;
  ${Neumorphism};

  &::before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 114%;
    background: url('/about.svg') no-repeat right 0 / cover;
    content: "";
  }

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin: 0 2rem 2rem;
  `}
`;

const Title = styled.h1`
  margin: 0 0 .5rem;
  color: ${Settings.colors.keyColor};
  font-size: .875rem;
`;

const Label = styled.span`
  display: block;
  margin-bottom: .5rem;
`;

const Image = styled(LogoImage)`
  display: inline-block;
  width: auto;
  height: 1.2rem;
  margin-right: .2rem;
`;

const Description = styled.div`
  margin: 0 0 .5rem;
  font-size: .875rem;
`;

const Button = styled.div`
  text-align: center;
`;

const ButtonLink = styled(Link)`
  display: block;
  padding: .5rem 0;
  border-radius: 100px;
  background-color: ${Settings.colors.keyColor};
  color: ${Settings.colors.baseColor};
  font-size: .75rem;
  text-decoration: none;
`;

const About = ({ settings }) => (
  <Container>
    <Title>
      <Label>Hello!</Label>
      <Image alt={ settings.siteName } />!!
    </Title>
    <Description dangerouslySetInnerHTML={{ __html: settings.siteCaption }} />
    <Button><ButtonLink to={ `/about` } title={`/about`}>詳しくみる</ButtonLink></Button>
  </Container>
)

About.propTypes = {
  settings: PropTypes.object,
}

export default About
