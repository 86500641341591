import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Side from 'src/components/organisms/Side'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`
`;

const Inner = styled.div`
  display: flex;
  max-width: 1068px;
  margin: 0 auto;

  ${Media.mobile`
    flex-direction: column;
  `}
`;

const Main = styled.main`

  ${Media.desktop`
    width: 728px;
    margin-right: auto;
  `}
`;

const Wrapper = ({ settings, children }) => (
  <Container>
    <Inner>
      <Main>
        { children }
      </Main>
      <Side settings={ settings } />
    </Inner>
  </Container>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  posts: PropTypes.array,
  settings: PropTypes.object,
}

export default Wrapper
